import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorHandler, inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Error } from '../dtos/error/error';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  http = inject(HttpClient);
  apiUrl = environment.api;
  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };

  constructor() {}
  handleError(error: any): void {
    console.error('Error occured: ', error);
    const err: Error = {
      message: error.message ?? error | error.error,
      stack: error.stack ?? error.error,
      timestamp: error.error.timestamp ?? new Date().toISOString(),
      url: error.url ? error.url : ' ',
    };

    this.sendDiagnostic(err).subscribe({
      next: (value) => {},
      complete: () => {
        console.log('logged');
      },
    });
  }

  sendDiagnostic(error: Error): Observable<Error> {
    return this.http.post<Error>(
      `${this.apiUrl}error`,
      error,
      this.httpOptions
    );
  }
}
