import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseService } from 'src/app/services/case.service';

@Component({
  selector: 'app-file-upload',
  standalone: true,
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  imports: [CommonModule],
})
export class FileUploadComponent {
  fileName = '';
  fileToUpload: File | null = null;
  status: ('initial' | 'uploading' | 'success' | 'fail')[] = []; // Variable to store file status
  files: File[] = []; // Initialize as an empty array
  outputFiles: any = [];
  @Input({ required: true }) caseId: number;
  @Output() selectedFiles: EventEmitter<any> = new EventEmitter<any>();
  caseService = inject(CaseService);
  cd = inject(ChangeDetectorRef);
  constructor() {}

  onFileSelected(event: any) {
    const fileList: FileList = event.target.files; // Get the FileList

    if (fileList.length) {

      this.files = Array.from(fileList); // Convert FileList to array
      this.selectedFiles.emit(this.files);
      this.cd.detectChanges();
    }
  }
  removeFileFromList(index: number) {
    if (index >= 0 && index < this.files.length) {
      // Check for valid index
      this.files.splice(index, 1); // Remove the file at the given index
      console.log(this.files); // Log updated files
    } else {
      console.error('Index out of bounds:', index); // Log an error if the index is invalid
    }
  }
  covertFileSizeToMB(fileSize: number) {
    return (fileSize / 1048576).toFixed(4);
  }
}
