import { NgIf, NgTemplateOutlet, NgFor } from '@angular/common';
import { ChangeDetectorRef, Component, TemplateRef } from '@angular/core';
import { NgbToast, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-toast',
  // templateUrl: './toast.component.html',
  standalone: true,
  imports: [NgbToastModule, NgIf, NgTemplateOutlet, NgFor],
  template: `
    <ngb-toast
      *ngFor="let toast of toastService.toasts"
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      (hidden)="toastService.remove(toast)"
    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ toast.textOrTpl }}</ng-template>
    </ngb-toast>
  `,
  host: {
    class: 'toast-container position-fixed top-20 end-0 p-3',
    style: 'z-index: 1200',
  },
})
export class ToastComponent {
  constructor(
    public toastService: ToastService,
    private cd: ChangeDetectorRef
  ) {
    let that = this;
    if (that.toastService.toasts.length > 0) {
      that.cd.detectChanges();
    }
  }
  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
