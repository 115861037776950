import {
  Component,
  HostListener,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Case } from 'src/app/dtos/case/Case';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  animations: [
    trigger('datatableAnimation', [
      state(
        'expanded',
        style({
          height: '280', // Use the actual height of the element
          opacity: 1,
          overflow: 'hidden',
        })
      ),
      state(
        'collapsed',
        style({
          height: '0', // Collapse the element to zero height
          opacity: 0,
          overflow: 'hidden',
        })
      ),
      transition('expanded <=> collapsed', [
        animate('300ms ease-in-out', style({ height: 'auto !important' })), // Duration and easing of the animation
      ]),
    ]),

    trigger('slideUpDown', [
      state(
        'expanded',
        style({
          height: '*', // Use the actual height of the element
          opacity: 1,
          overflow: 'hidden',
        })
      ),
      state(
        'collapsed',
        style({
          height: '0', // Collapse the element to zero height
          opacity: 0,
          overflow: 'hidden',
        })
      ),
      transition('expanded <=> collapsed', [
        animate('300ms ease-in-out'), // Duration and easing of the animation
      ]),
    ]),
  ],
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class FilterComponent implements OnInit, OnChanges {
  @Input() cases: Case[];
  totalCasesCount: number = 0;
  suspiciousCasesCount: number = 0;
  notSuspiciousCasesCount: number = 0;
  closedCasesCount: number = 0;
  casesThisMonthCount: number = 0;
  averageCasesPerDay: number = 0;
  @Output() appliedRows = new EventEmitter<any>();
  // @Output() rows: EventEmitter<Case> = new EventEmitter<Case>();

  // cases: Case[] = [];

  expanded: any = {};
  timeout: any;
  temp: any = [];

  investigationResult: string = 'all';

  onPage(event: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {}, 100);
  }
  fromDate: string;
  currentDay: string;

  constructor() {
    const today = new Date();
    // Format today's date for maxDay
    this.currentDay = today.toISOString().split('T')[0];

    // Calculate tomorrow's date for fromDate
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    this.fromDate = tomorrow.toISOString().split('T')[0];
  }
  dateChanged(event: any) {
    console.log(this.fromDate);
  }
  ngOnInit(): void {
    if (this.cases) {
      this.temp = this.cases;
    }
    console.log(this.cases);
    this.calculateCounts();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.temp = this.cases;
  }

  resetRows() {
    this.resetFields();
    //Reset the rows of the table to original data
    this.temp = this.cases;
    //Reset the filtered rows to original data
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
    this.appliedRows.emit(this.temp);
  }

  resetFields() {
    //reset the value to empty string
    let selectTag = document.querySelectorAll('select');
    selectTag.forEach((element) => {
      element.value = '';
    });

    //Clears ID field
    const id = document.querySelector('input[name="id"]') as HTMLInputElement;
    id.value = 'Filter by ID...';
    //Clears Date Field
    const fromDateInput = document.querySelector(
      'input[name="fromDate"]'
    ) as HTMLInputElement;
    const toDateInput = document.querySelector(
      'input[name="toDate"]'
    ) as HTMLInputElement;

    fromDateInput.value = '';
    toDateInput.value = '';
  }

  apply() {
    this.temp = this.cases;
    // update each filter function based on the selected options/values
    this.updateFilterByClaimSeverity();
    this.updateFilterByInvestigationResult();
    this.updateFilterByDateRange();
    this.updateFilterById();
    this.updateFilterBySystemResult();
    this.updateFilterByPriorityIndex();
    this.appliedRows.emit(this.temp);
  }

  /*Listens for key press  and updates the result*/
  @HostListener('document:keyup', ['$event'])
  handleEnterKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.apply();
    }
  }

  updateFilterByClaimSeverity() {
    const claimSeveritySelect = document.querySelector(
      'select[name="claimSeverity"]'
    ) as HTMLSelectElement;
    if (claimSeveritySelect) {
      const selectedSeverity = claimSeveritySelect.value.toLowerCase();
      if (selectedSeverity !== '') {
        this.temp = this.temp.filter((d: { caseSeverity: any }) => {
          if (d.caseSeverity !== null) {
            return d.caseSeverity.toLowerCase() === selectedSeverity;
          }
        });
      }
    }
  }

  updateFilterByInvestigationResult() {
    const investigationResultSelect = document.querySelector(
      'select[name="investigationResult"]'
    ) as HTMLSelectElement;
    if (investigationResultSelect) {
      const selectedResult = investigationResultSelect.value.toLowerCase();
      if (selectedResult !== '') {
        this.temp = this.temp.filter((d: any) => {
          return d.investigationResults.toLowerCase() === selectedResult;
        });
      }
    }
  }

  updateFilterByDateRange() {
    const fromDateInput = document.querySelector(
      'input[name="fromDate"]'
    ) as HTMLInputElement;
    const toDateInput = document.querySelector(
      'input[name="toDate"]'
    ) as HTMLInputElement;

    const fromDateVal = new Date(fromDateInput.value);
    const toDateVal = new Date(toDateInput.value);
    if (fromDateInput.value && toDateInput.value) {
      this.temp = this.temp.filter((d: { started: Date }) => {
        const caseDate = new Date(d.started);
        return caseDate >= fromDateVal && caseDate <= toDateVal;
      });
    }
  }

  updateFilterById() {
    const idInput = document.querySelector(
      'input[name="id"]'
    ) as HTMLInputElement;
    const idValue = Number(idInput.value);

    if (!isNaN(idValue) && idInput.value.length === 4) {
      this.temp = this.temp.filter(
        (d: { caseId: any }) => Number(d.caseId) === idValue
      );
    }
  }
  updateFilterBySystemResult() {
    const sysResSelect = document.querySelector(
      'select[name="systemResult"]'
    ) as HTMLSelectElement;
    if (sysResSelect) {
      const selectedResult = sysResSelect.value.toLowerCase();
      if (selectedResult !== '') {
        this.temp = this.temp.filter((d: { systemResult: any }) => {
          d.systemResult = d.systemResult.split(' ').join('');
          return d.systemResult.toLowerCase() === selectedResult;
        });
      }
    }
  }

  updateFilterByPriorityIndex() {
    const minPriorityElement = document.querySelector(
      'input[name="priorityIndexMin"]'
    ) as HTMLInputElement | null;

    const maxPriorityElement = document.querySelector(
      'input[name="priorityIndexMax"]'
    ) as HTMLInputElement | null;

    if (!minPriorityElement || !maxPriorityElement) {
      console.error('Priority index input elements not found');
      return;
    }

    const minPriority = parseInt(minPriorityElement.value, 10);
    const maxPriority = parseInt(maxPriorityElement.value, 10);

    if (isNaN(minPriority) || isNaN(maxPriority)) {
      return;
    }

    if (minPriority < maxPriority) {
      this.temp = this.temp.filter((d: { priorityIndex: any }) => {
        const priorityIndex = Number(d.priorityIndex);
        const result =
          priorityIndex * 100 >= minPriority &&
          priorityIndex * 100 <= maxPriority;
        // Log each comparison result
        return result;
      });
    }
  }

  // Apply the Investigation Result filter (buttons)
  toggleInvestigationResult(result: string) {
    if (this.investigationResult === result) {
      this.resetInvestigationResult();
    } else {
      this.investigationResult = result;
      this.applyInvestigationResultFilter();
    }
  }

  applyInvestigationResultFilter() {
    this.temp = this.cases;
    if (this.investigationResult != 'all') {
      this.temp = this.temp.filter((d: any) => {
        return (
          d.investigationResults.toLowerCase() ===
          this.investigationResult.toLowerCase()
        );
      });
      this.appliedRows.emit(this.temp);
    } else {
      this.resetInvestigationResult();
    }
  }

  resetInvestigationResult() {
    this.investigationResult = 'all';
    this.temp = this.cases;
    this.appliedRows.emit(this.temp);
  }
  //
  calculateCounts() {
    this.totalCasesCount = this.cases.length;

    this.suspiciousCasesCount = this.cases.filter(
      (caseItem: Case) => caseItem?.systemResult?.toLowerCase() === 'suspicious'
    ).length;
    this.notSuspiciousCasesCount = this.cases.filter(
      (caseItem: Case) =>
        caseItem?.systemResult?.toLowerCase() === 'notsuspicious'
    ).length;
  }

  setAdditionalStatistics(): void {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    this.closedCasesCount = this.cases.filter(
      (caseItem) => caseItem?.investigationResults?.toLowerCase() === 'reviewed'
    ).length;

    // Assuming 'reportDate' is a Date object or a string that can be converted to a Date object
    this.casesThisMonthCount = this.cases.filter((caseItem) => {
      const reportDate = new Date(caseItem?.created);
      return (
        reportDate.getMonth() === currentMonth &&
        reportDate.getFullYear() === currentYear
      );
    }).length;

    // Calculate the average cases per day (assuming 'reportDate' is within the current year for simplicity)
    const startOfYear = new Date(currentYear, 0, 1);
    const daysElapsed = Math.ceil(
      (currentDate.getTime() - startOfYear.getTime()) / (1000 * 60 * 60 * 24)
    );
    this.averageCasesPerDay = this.cases.length / daysElapsed;
  }

  getTotalCasesCount(): number {
    return this.cases.length;
  }

  getSuspiciousCasesCount(): number {
    return this.cases.filter(
      (caseItem) => caseItem.systemResult.toLowerCase() === 'suspicious'
    ).length;
  }

  getNotSuspiciousCasesCount(): number {
    return this.cases.filter(
      (caseItem) => caseItem.systemResult.toLowerCase() === 'notsuspicious'
    ).length;
  }

  showFilters: boolean = false;
  showStatistics: boolean = true;

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  toggleStatistics() {
    this.showStatistics = !this.showStatistics;
  }
}
