<div class="container">
  <div class="row align-items-center">
    <input type="file" class="file-input btn btn-primary d-none" (change)="onFileSelected($event)" accept=".pdf,.doc,.docx"
      #fileUpload multiple />

    <div class="btn btn-primary text-white" (click)="fileUpload.click()">
      <span class="me-5" >{{ "Choose a file" }}
      </span>
      <i class="fa-solid fa-file fa-2xl" style="color:white"></i>
    </div>
  </div>

  <!-- <input type="file" class="file-input" multiple (change)="onChange($event)" /> -->

  <div *ngIf="files.length" class="row align-items-center">
    @if(files){
    <div class="col-12" style="height: 100px; overflow: auto;">
      @for (file of files; track file; let index = $index) {
      <section>
        File details:
        <div class="d-flex align-items-center gap-3" >
        <ul class=>
          <li>Name: {{file.name}}</li>
          <li>Size: {{covertFileSizeToMB(file.size)}} MB</li> 
        </ul>
        <button class="btn btn-primary btn-sm text-white" (click)="removeFileFromList(index)">X</button>
      </div>
      </section>
    }
    </div> 
    }
  </div>
</div>