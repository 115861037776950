import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslationService } from './modules/i18n';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { FaviconService } from './services/favicon.service';

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnInit {
  env = environment;

  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private titleService: Title,
    private faviconService: FaviconService
  ) {}

  ngOnInit() {
    if (this.env.brand.code == 1) {
      this.titleService.setTitle('Coreact');
      this.faviconService.setFavicon('assets/media/logos/coreact.png');
    } else {
      this.titleService.setTitle('Coassess');
      this.faviconService.setFavicon('assets/media/logos/coassess-small.png');
    }
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
    this.modeService.init();
  }
}
